<template>
  <div class="col">
    <div class="card">
      <div class="card-body placeholder-glow">
        <h5 class="card-title">Olosuhteet</h5>
        <div :class="{ placeholder: !log.datetime }">
          <span class="bi-chevron-double-down" /> Ilmanpaine:
          <span v-if="log.datetime">{{ log.qff60savg }}</span>
          <span v-if="!log.datetime">000</span>
          hPa
        </div>
        <div :class="{ placeholder: !log.datetime }">
          <span class="bi-moisture" /> Suht. ilmankosteus:
          <span v-if="log.datetime">{{ log.rh60savg }}</span>
          <span v-if="!log.datetime">000</span>
          %
        </div>
        <div :class="{ placeholder: !log.datetime }">
          <span class="bi-moisture" /> Abs. ilmankosteus:
          <span v-if="log.datetime">{{ log.ah60savg }}</span>
          <span v-if="!log.datetime">000</span>
          g/m3
        </div>
        <div :class="{ placeholder: !log.datetime }">
          <span class="bi-cloud-rain" /> Sademäärä (1min):
          <span v-if="log.datetime">{{ log.pr60ssum }}</span>
          <span v-if="!log.datetime">000</span>
          mm/m2
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConditionsCard",
  props: ["log"],
};
</script>

<style scoped>
</style>
